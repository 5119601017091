<template>
  <span v-for="field in fields" :key="field.id">
    <checkout-page-custom-field :field="field" />
    <br />
  </span>
</template>

<script lang="ts" setup>
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";

const props = defineProps<{
  type: "pre" | "post";
}>();

const optionsStore = useCheckoutStore();

const fields = computed(() => {
  return props.type === "pre"
    ? optionsStore.getOptions.preProductOptions
    : optionsStore.getOptions.postProductOptions;
});
</script>

<style scoped></style>
