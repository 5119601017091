<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";

const checkoutStore = useCheckoutStore();

const v$ = useVuelidate({}, {});
</script>

<template>
  <span>
    <v-table>
      <thead>
        <tr>
          <th class="text-left">Product</th>
          <th class="text-left">Prijs</th>
          <th class="text-left">Hoeveelheid</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="item in checkoutStore.getAvailableProducts"
          :key="item.id"
        >
          <checkout-page-product-table-entry :product="item" />
        </template>
      </tbody>
    </v-table>
    <br />
    <template v-if="v$.$errors.length > 0">
      <v-messages
        :active="true"
        :messages="['De hoeveelheid kan alleen een geheel getal zijn.']"
        color="error"
      />
      <br />
    </template>
    <!--      <p class="text-red">-->
    <!--        De hoeveelheid kan alleen een geheel getal zijn.-->
    <!--      </p>-->
  </span>
</template>

<style scoped></style>
