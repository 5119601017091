// function deliveryDataValidator (value, )
import * as validators from "@vuelidate/validators";
import { helpers } from "@vuelidate/validators";
import type { Ref } from "vue";
import type { ValidationRule, ValidationRuleWithParams } from "@vuelidate/core";

export const required: ValidationRule = helpers.withMessage(
  "Dit veld is verplicht.",
  validators.required,
);

export const requiredIf = (
  cond: string | boolean | Ref<boolean> | (() => boolean | Promise<boolean>),
): ValidationRuleWithParams =>
  helpers.withMessage("Dit veld is verplicht.", validators.requiredIf(cond));

export const numeric = helpers.withMessage(
  "Gebruik alstublieft alleen getallen.",
  validators.numeric,
);

export const email = helpers.withMessage(
  "Vul alstublieft een geldig emailadres in.",
  validators.email,
);

export const telephone = helpers.withMessage(
  "Het ingevulde telefoonnummer is ongeldig.",
  (value: string) => {
    const val = value.replaceAll(" ", "");
    return !!val.match(/^[0-9]{10}$/g);
  },
);

export const postalCode = helpers.withMessage(
  "De ingevulde postcode is ongeldig.",
  (value: string) =>
    !!value.replaceAll(" ", "").match(/[0-9]{4}([a-zA-Z]{2})/g),
);

export const houseNumber = helpers.withMessage(
  "Het ingevulde huisnummer is ongeldig.",
  (value: string) =>
    (value.replaceAll(" ", "").match(/[0-9]{1,3}([a-zA-Z]{0,2})/g) ?? [])
      .length > 0,
);

export const integer = helpers.withMessage(
  "Dit veld moet een geheel getal zijn.",
  validators.integer,
);
export const decimal = (decimals: number) =>
  helpers.withMessage(
    ({ $params }) =>
      `Dit veld moet een getal zijn met maximaal ${$params.decimals} getallen achter de komma.`,
    helpers.regex(new RegExp(`^[0-9]+([,.][0-9]{1,${decimals}})?$`)),
  );

export const minValue = (minimum: number) =>
  helpers.withMessage(
    ({ $params }) => `Het getal mag niet lager zijn dan ${$params.min}.`,
    validators.minValue(minimum),
  );
export const maxValue = (maximum: number) =>
  helpers.withMessage(
    ({ $params }) => `Het getal mag niet hoger zijn dan ${$params.max}.`,
    validators.maxValue(maximum),
  );
export const minLength = (minimum: number) =>
  helpers.withMessage(
    ({ $params }) =>
      `De hoeveelheid tekens mag niet lager zijn dan ${$params.min}.`,
    validators.minLength(minimum),
  );
export const maxLength = (maximum: number) =>
  helpers.withMessage(
    ({ $params }) =>
      `De hoeveelheid tekens mag niet hoger zijn dan ${$params.max}.`,
    validators.maxLength(maximum),
  );
