<template>
  <template v-if="checkoutStore.getOptions.productOptions.mode === 'PRODUCT'">
    <checkout-page-product-table />
  </template>
  <template
    v-else-if="checkoutStore.getOptions.productOptions.mode === 'SERVICE'"
  >
    <div class="text-subtitle-1 text-medium-emphasis">Type bezoek*</div>
    <v-select
      data-cy="service-selector"
      :items="checkoutStore.getAvailableProducts"
      density="compact"
      item-value="id"
      item-title="label"
      placeholder="Maak een keuze voor het type bezoek"
      variant="outlined"
      @update:model-value="checkoutStore.setService"
    >
      <template #item="{ props, item }">
        <v-list-item
          v-bind="props"
          :title="`${item.raw.label} ${formatPrice(item.raw.price)}`"
        ></v-list-item>
      </template>
      <template #selection="{ item }">
        {{ `${item.raw.label} ${formatPrice(item.raw.price)}` }}
      </template>
    </v-select>
    <div v-if="checkoutStore.getService">
      <v-alert>
        {{ checkoutStore.getService.description }}
      </v-alert>
      <br />
    </div>
  </template>
  <template v-if="hasSelectedProduct && checkoutStore.getTimeslots.length == 0">
    <v-alert type="error" data-cy="timeslots-at-capacity-alert">
      {{ $t("checkout.allTimeslotsAtCapacity") }}
    </v-alert>
    <br />
  </template>
  <template v-else>
    <div v-if="checkoutStore.getTimeslotMessage != undefined">
      <v-alert
        :color="checkoutStore.getTimeslotMessage.type"
        :icon="'$' + checkoutStore.getTimeslotMessage.type"
        alert-plain-transition="slide-y-transition"
      >
        {{ checkoutStore.getTimeslotMessage.text }}
      </v-alert>
      <br />
    </div>
    <div class="text-subtitle-1 text-medium-emphasis">
      {{ $t("checkout.deliveryDay") }}*
    </div>
    <v-select
      v-model="selectedDeliveryDay"
      data-cy="day-selector"
      :disabled="!hasSelectedProduct"
      :items="getDays"
      density="compact"
      item-title="label"
      placeholder="Kies een dag uit de lijst"
      return-object
      variant="outlined"
      @input="checkoutStore.setService"
    />

    <div>
      <div class="text-subtitle-1 text-medium-emphasis">
        {{ $t("checkout.timeslot") }}*
      </div>
      <v-select
        v-model="checkoutStore.order.fulfillment.timeslot"
        data-cy="slot-selector"
        :disabled="getTimeslots.length == 0"
        :error-messages="getVuelidateErrors(v$.timeslot!.$errors)"
        :items="getTimeslots"
        density="compact"
        item-title="label"
        item-value="id"
        placeholder="Tijdvak"
        variant="outlined"
      />
    </div>
  </template>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import type { Ref } from "vue";
import type { deliveryDay, deliverySlot } from "~/composables/store/checkout";
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";

const checkoutStore = useCheckoutStore();

const hasSelectedProduct = computed(() => {
  return (
    checkoutStore.order.products.reduce((acc, item) => acc + item.amount, 0) > 0
  );
});

const selectedDeliveryDay: Ref<deliveryDay | undefined> = ref(undefined);

checkoutStore.$onAction(({ name, store }) => {
  if (store !== checkoutStore) {
    return;
  }
  if (name !== "changeProduct" && name !== "setService") {
    return;
  }

  // Reset the timeslot and deliveryday
  selectedDeliveryDay.value = undefined;
  checkoutStore.order.fulfillment.timeslot = undefined;
});

watch(selectedDeliveryDay, () => {
  checkoutStore.order.fulfillment.timeslot = undefined;
});

type labeledSlot = deliverySlot & {
  label?: string;
};

// Which timeslots are available?
const getTimeslots = computed((): labeledSlot[] => {
  if (!selectedDeliveryDay.value) {
    return [];
  }
  const dayUnref = unref(selectedDeliveryDay) as deliveryDay;
  const slotsUnref: labeledSlot[] = dayUnref.slots;
  for (const slot of slotsUnref) {
    slot.label = parseTime(slot);
  }

  return slotsUnref;
});

type labeledDay = deliveryDay & {
  label?: string;
};

const getDays = computed((): labeledDay[] => {
  const dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  } as Intl.DateTimeFormatOptions;
  const daysUnref = unref(checkoutStore.getTimeslots) as labeledDay[];
  for (const day of daysUnref) {
    day.label = new Date(day.date).toLocaleDateString("nl-NL", dateOptions);
    day.label = day.label.charAt(0).toUpperCase() + day.label.slice(1);
  }
  return daysUnref;
});

const v$ = useVuelidate(
  checkoutStore.getDeliveryOptionsValidationRules,
  checkoutStore.order.fulfillment,
);

const parseTime = (item: deliverySlot): string => {
  const startTime = item.start.slice(0, -3);
  const endTime = item.end.slice(0, -3);
  return startTime + " - " + endTime;
};
</script>

<style scoped></style>
