<template>
  <!-- Choosing the delivery method -->
  <template v-if="deliveryEnabled && pickupEnabled">
    <div class="text-subtitle-1 text-medium-emphasis">Levermethode*</div>
    <v-radio-group
      v-model="checkoutStore.order.fulfillment.deliver"
      inline
      mandatory
      @update:model-value="updateFullfilmentMethod"
    >
      <v-radio data-cy="delivery" :value="true" label="Bezorgen" />
      <v-radio data-cy="pickup" :value="false" label="Afhalen" />
    </v-radio-group>
  </template>

  <!--  Delivery method = delivery -->
  <checkout-page-location-delivery
    v-if="checkoutStore.order.fulfillment.deliver"
    @set-location-information="setLocationInformation"
  />

  <!-- Delivery method = pickup -->
  <checkout-page-location-pickup
    v-else
    @set-location-information="setLocationInformation"
  />
</template>

<script lang="ts" setup>
import { useCheckoutStore } from "~/composables/store/checkout/CheckoutStore";

const checkoutStore = useCheckoutStore();

const deliveryEnabled = ref(true);
const pickupEnabled = computed(() => {
  return checkoutStore.getOptions.pickupPoints.length > 0;
});

export type LocationInformation = {
  locationFee: number;
  products: {
    id: number;
    price: number;
    timeslots: number[];
  }[];
};
const setLocationInformation = (information: LocationInformation) => {
  // Update prices, timeslots and location fee
  for (const product of checkoutStore.getOptions.productOptions.products) {
    const bind = information.products.find((item) => item.id === product.id);
    if (bind) {
      product.price = bind.price;
      product.timeslots = bind.timeslots;
    } else {
      product.price = -1;
      product.timeslots = [];
    }
  }
  checkoutStore.locationFee = information.locationFee;

  checkoutStore.locationVerified = true;

  // Reset the parts of the form that are relevant on the location / delivery method
  // TODO: GA-67 Reset form only when needed.
  for (const product of checkoutStore.order.products) {
    checkoutStore.changeProduct(product.id, 0);
  }
  checkoutStore.order.fulfillment.timeslot = undefined;
};

const updateFullfilmentMethod = () => {
  setLocationInformation({
    locationFee: 0,
    products: [],
  });

  // Reset the location verified part AFTER setting location information
  // as setting location information will set it to true
  checkoutStore.locationVerified = false;

  if (checkoutStore.order.fulfillment.deliver) {
    checkoutStore.order.fulfillment.data = {
      postalCode: "",
      houseNumber: "",
      houseNumberSuffix: "",
    };
  } else {
    checkoutStore.order.fulfillment.data.pickupPoint = undefined;
  }
};
</script>

<style scoped></style>
